import CartCalculatorService from "../Service/CartCalculatorService";
import checkoutPageMapper from "../Mapper/CheckoutPageMapper";
import AppHelperService from "../../../js/Helper/AppHelperService";

class CheckoutPageDom {
    constructor() {
        this.mapper = checkoutPageMapper;
        this.calculator = new CartCalculatorService();
    }

    updateOrderPrice() {
        const country = $(this.mapper.countryOptions).val();
        const payment = $(`${this.mapper.paymentType}:checked`).val();
        let productList = [];

        $.each($(`${this.mapper.products} li.products`), (index, elm) => {
            productList.push($(elm));
        });

        const calculator = this.calculator.calculate(productList, country, payment);

        if (-1 < window.DISCOUNT) {
            $(this.mapper.promoCouponPrice).text(`${window.DISCOUNT} %`);
            $(this.mapper.promoCouponPrice).closest('.promo-coupon-holder').removeClass('hide');
        }

        $(this.mapper.shippingPriceText).text(calculator.shippingPrice.price_hrk.current);
        $(this.mapper.euroShippingPriceText).text(calculator.shippingPrice.price_euro.current);
        $(this.mapper.orderTotalPriceInput).val(calculator.total.price_euro.absolute);
        $(this.mapper.orderTotalPrice).text(calculator.total.price_hrk.current);
        $(this.mapper.euroOrderTotalPrice).text(calculator.total.price_euro.current);
    }

    uncheckAndDisablePaymentsByCountry() {
        const country = $(this.mapper.countryOptions).val();
        let availablePaymentsListId = {};

        $(`${this.mapper.paymentType}:checked`).prop('checked', false);

        if (country == -1) {
            $(`${this.mapper.paymentType}`).removeAttr('disabled');

        } else {
            $(`${this.mapper.paymentType}`).attr('disabled', 'disabled');

        }

        for (let i = 0; i < SHIPPINGS.length; i++) {
            let shipping = SHIPPINGS[i];

            if (shipping.countries.includes(country)) {
                for (const payment of shipping.payments) {
                    $(`${this.mapper.paymentType}[value="${payment}"]`).removeAttr('disabled');
                    availablePaymentsListId[payment] = true;

                }
            }
        }

        const availableIds = Object.keys(availablePaymentsListId);

        if (1 === availableIds.count) {
            $(`${this.mapper.paymentType}[value="${availableIds[0]}"]`).prop('checked', true);
        }
    }
}

export default CheckoutPageDom;
