import AppHelperService from "../../../js/Helper/AppHelperService";
import shopPageMapper from "../Mapper/ShopPageMapper";
import coreMapper from "../Mapper/CoreMapper";

class ShopPageDom {
    constructor() {
        if (!ShopPageDom.instance) {
            this.mapper = shopPageMapper;
            this.coreMapper = coreMapper;

            ShopPageDom.instance = this;
        }

        return ShopPageDom.instance;
    }

    generateProducts(data) {
        let html = '';

        for(let i in data.products.data) {
            let product = data.products.data[i];
            let oldPriceHtml = '';
            let availableSizesHtml = '';
            let discountBanner = '';
            let productPriceText = 'price';
            const productLink = Routing.generate(`site.product_page.${LOCALE}`, {'slug': product.slug});
            const wishListClass = product.has_wish == 1 ? 'fa-heart' : 'fa-heart-o';

            if (product.discount > 0) {
                oldPriceHtml = `
                            <div class="sfi-price-rating">
                                <p class="sfi-price text-uppercase">
                                    <span class="sfi-price-text">${ Translator.trans('lowest_price_in_last_30_days_short') }</span>
                                    <span>${product.price_euro.default}
                                    (${product.price_hrk.default})</span>
                                </p>
                            </div>`;
                productPriceText = 'actual_price';
                discountBanner = `<div class="sfi-img-banner">
                                    <span>${AppHelperService.calculatePercentage(product.price, product.discount)} %</span>
                                </div>`;
            }

            if (null !== data.product_sizes[product.id]) {
                availableSizesHtml = `<div class="sfi-img-content sfi-data-content">
                                    <p class="text-capitalize">${Translator.trans('available_sizes', null, 'messages', LOCALE)}:</p>
                                    <ul>${this.listData(data.product_sizes[product.id])}</ul>
                                </div>`;
            }

            html += `<div class="item-grid">
                        <div class="single-featured-item">
                            <div class="sfi-img">
                                <a href="${productLink}"><img src="${product.image_link_list}" alt="{{ default_alt_tag }}"></a>
                                <ul class="sfi-tag-list">${this.listData(data.product_tags[product.id], 'tags')}</ul>
                                ${availableSizesHtml}
                                ${discountBanner}
                            </div>
                            <div class="sfi-content">
                                <div class="sfi-buttons">
                                    <ul class="clearfix">
                                        <li><a href="#" class="toggle-wish-list"><i class="fa ${wishListClass}"></i></a></li>
                                        <li><a href="${productLink}" ><i class="fa fa-eye"></i></a></li>
                                    </ul>
                                </div>
                                <div class="sfi-name-cat">
                                    <a class="sfi-name" href="${productLink}">${product.title}</a>
                                </div>
                                <div class="sfi-price-rating">
                                    <p class="sfi-price text-uppercase">
                                        <span class="sfi-price-text">${Translator.trans(productPriceText)}</span>
                                        <span>${product.price_euro.current}
                                        (${product.price_hrk.current})</span>
                                    </p>
                                </div>
                                ${oldPriceHtml}
                            </div>
                        </div>
                    </div>`;
        }

        return html;
    };

    /**
     *
     * @param {array} breadcrumbs
     */
    generateBreadcrumbs(breadcrumbs)
    {
        $(this.coreMapper.breadcrumbList)
            .empty()
            .append(`<li><a href="${breadcrumbs.length === 0 ? Routing.generate('site.home_page') : '#'}" class="${breadcrumbs.length === 0 ? 'active' : ''}">${Translator.trans('navi.home', null, 'messages', LOCALE)}</a></li>`);

        const length = breadcrumbs.length;
        let i = 0;

        for (const breadcrumb of breadcrumbs) {
            let isActive = length - 1 === i;

            $(this.coreMapper.breadcrumbList)
                .append(`<li class="${true === isActive ? 'active' : ''} letter-capitalize"><a href="${false === isActive ? breadcrumb.link : '#'}">${breadcrumb.title}</a></li>`);

            i++;
        }
    }

    listData(data, type) {
        let html = '';

        for(let i in data) {
            if (type === 'tags') {
                const tagKey = Translator.trans('tags', null, 'messages', LOCALE);

                const link = AppHelperService.generateLocalizedUrl('site.trendy_page', {'searchData': `${tagKey}/${data[i].slug}`})

                html += `<li><a href="${link}">${data[i].label}</a></li>`;

                continue;
            }
            html += `<li><a href="#">${data[i]}</a></li>`;
        }

        return html;
    }

    hideLoadMore()
    {
        $(this.mapper.loadMore).addClass('hide');
        $(this.mapper.noMoreItemsText).removeClass('hide');
    }

    showLoadMoreButton()
    {
        $(this.mapper.loadMore).removeClass('hide');
        $(this.mapper.noMoreItemsText).addClass('hide');
    }

    productColors(colors) {
        let html = '';

        for(let i in colors) {
            html += `<li><a style="background-color: ${colors[i]}" href="#"></a></li>`;
        }

        return html;
    }

    removeAllCriteriaOnPageByName(name)
    {
        $(`[data-name=${name}]`).remove();
        $(`[data-search-name="${name}"]`).removeClass('active');
    }
}

const shopPageDom = new ShopPageDom();
Object.freeze(shopPageDom);

export default shopPageDom;
