import cartPageMapper from "../Mapper/CartPageMapper";
import AppHelperService from "../../../js/Helper/AppHelperService";

class CartPageDom {
    constructor() {
        this.pageMapper = cartPageMapper;
    }

    updateTotalWidget(data) {
        this.#updateShippingPrice(data.shipping_price);
        this.#updatePromotion(data.promo_code, data.promo_price);
        this.#updateItemsTotal(data.items_total);
        this.#updateTotal(data.total);
        this.#updateProduct(data.products);
    }

    #updateShippingPrice(shippingPrice) {
        this.pageMapper.shippingPrice.text(shippingPrice.price_hrk.current);
        this.pageMapper.euroShippingPrice.text(shippingPrice.price_euro.current);
    }

    #updatePromotion(promoCode, promoPrice) {
        if (null === promoCode) {
            return;
        }
        const promoRow = this.pageMapper.promoCouponHolder;

        this.pageMapper.promoCouponPrice.text(promoPrice);

        if (promoRow.hasClass('hide')) {
            promoRow.removeClass('hide');
        }
    }

    #updateItemsTotal(itemsTotal) {
        this.pageMapper.total.text(itemsTotal.price_hrk.current);
        this.pageMapper.totalEuro.text(itemsTotal.price_euro.current);
    }

    #updateTotal(total) {
        this.pageMapper.totalShipping.data('totalWithShipping', total.price_euro.absolute);
        this.pageMapper.totalShipping.text(total.price_hrk.current);
        this.pageMapper.euroTotalShipping.text(total.price_euro.current);
    }

    #updateProduct(products)
    {
        for (const product of products) {
            const tdEl = $(`#${product.id}`);

            $(this.pageMapper.productTotalPriceEuro, tdEl).text(product.total.price_euro.current);
            $(this.pageMapper.productTotalPriceHrk, tdEl).text(product.total.price_hrk.current);
        }
    }
}

const cartPageDom = new CartPageDom();

Object.freeze(cartPageDom);

export default cartPageDom;
